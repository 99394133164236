import { useQuery } from '@tanstack/react-query';
import { getProduct } from '../services/introduction';

export const useProduct = (accessToken: string, productConfigurationUrl: string | undefined) => {
  return useQuery({
    queryKey: ['product', productConfigurationUrl],
    queryFn: () => getProduct(accessToken, productConfigurationUrl as string),
    enabled: !!productConfigurationUrl,
  });
};
