import { useQuery } from '@tanstack/react-query';
import { getFulfillmentConfiguration } from '../services/fulfillmentConfiguration';

export const useFulfillmentConfiguration = (accessToken: string, fulfillmentConfigurationUrl: string | undefined) => {
  return useQuery({
    queryKey: ['fulfillmentConfiguration', fulfillmentConfigurationUrl],
    queryFn: () => getFulfillmentConfiguration(accessToken, fulfillmentConfigurationUrl as string),
    enabled: !!fulfillmentConfigurationUrl,
  });
};
