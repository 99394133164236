import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import { fetchWithAuth } from '../services/fetchWithAuth';
import { isRequestResponseLikelyToChange } from '../helpers/errorHandling';
import { HttpError } from '../types';

const MAX_RETRIES = 2;

export const useResource = <T>(accessToken: string, url: string | undefined) => {
  const queryClient = useQueryClient();

  /**
   * Check the Tanstack Query cache to see if we have an error for the query key.
   * If we do and it's an error that's not likely to have changed (i.e., inventory not configured),
   * then we can tell Tanstack Query not to try to re-try the request when the component
   * mounts again (like when you change tabs).
   */
  const queryCacheState = queryClient.getQueryState(['url', url]);
  const shouldRetryOnMount = isRequestResponseLikelyToChange(url, get(queryCacheState, ['error', 'status']));

  return useQuery({
    queryKey: ['url', url],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => fetchWithAuth<T>({ accessToken, endpointUrl: url! }),
    enabled: !!url,
    retry: (failureCount: number, error: HttpError) => {
      // Only retry the request if we think a retry might work (such as for transient errors)
      // and we haven't exceeded the maximum number of retry attempts
      if (!isRequestResponseLikelyToChange(url, error.status)) {
        return false;
      }

      return failureCount <= MAX_RETRIES;
    },
    retryOnMount: shouldRetryOnMount,
  });
};
