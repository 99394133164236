import React from 'react';
import { css } from '@emotion/css';
import { isEmpty } from 'lodash';
import { Copy, Table } from '@cimpress/react-components';
import { BranchLinks } from '../services/itemDeliveryPossibilities';
import { Tooltip } from '@cimpress/react-components';

type LinkMap = {
  name: string;
  href: string;
};

const tableCss = css`
  .crc-table {
    table-layout: fixed;
  }

  .crc-table th:nth-child(2),
  .crc-table td:nth-child(2) {
    width: 75%;
  }
`;

const linkCss = css`
  display: flex;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    width: 100%;
    display: block;
  }
`;

const tooltipCss = css`
  max-width: 97%;
`;

const LINK_NAME_MAP = {
  cutoffTimes: 'Cutoff Times',
  fulfiller: 'Fulfiller Identity',
  productConfiguration: 'Product Configuration',
  fulfillmentConfiguration: 'Fulfillment Process',
  fulfillmentLocation: 'Fulfillment Location',
  inventory: 'Inventory',
  productionTimeEvaluation: 'Production Time Evaluation',
  packEvaluation: 'Pack Evaluation',
  shipDate: 'Ship Date',
  prepressEstimate: 'Prepress Estimate',
  merchant: 'Merchant',
  buyerAccount: 'Buyer Account',
};

export const LinksTable = ({ links }: { links: BranchLinks }) => {
  const reformattedLinks = Object.entries(links)
    .filter(([, val]) => !isEmpty(val))
    .map(([key, val]): LinkMap => {
      if (key === 'inventory') {
        return {
          name: key,
          href: `${val.href}&showDebug=true`,
        };
      }

      return {
        name: key,
        href: val.href,
      };
    });

  const columns = [
    {
      Header: 'Resource',
      accessor: 'name',
      Cell: ({ original: row }: { original: LinkMap }) => LINK_NAME_MAP[row.name as keyof typeof LINK_NAME_MAP],
    },
    {
      Header: 'Link',
      accessor: 'href',
      Cell: ({ original: row }: { original: LinkMap }) => {
        return (
          <div className={linkCss}>
            <Tooltip
              containerClassName={tooltipCss}
              contents={row.href}
              tooltipInnerStyle={{
                width: 'max-content',
                maxWidth: '600px',
                wordWrap: 'break-word',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-all',
              }}
            >
              <span>
                <a href={row.href} target="_blank" rel="noreferrer">
                  {row.href}
                </a>
              </span>
            </Tooltip>
            <Copy
              style={{ background: 'none' }}
              value={row.href}
              variant="text"
              successMessage="Copied!"
              hoverText="Click to copy"
            >
              <></>
            </Copy>
          </div>
        );
      },
    },
  ];

  const sortedLinks = reformattedLinks.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div className={tableCss}>
      <Table columns={columns} data={sortedLinks} />
    </div>
  );
};
